.header-wrapper-2,
#nav-wrapper,
.search-by-type,
.see-all-recipes,
.section.related-recipes,
.cheese-filters,
.cheese-navigation-top,
.cheese-navigation-bottom,
.overlay-wrapper,
.cta-bottom,
.nav-primary,
.nav-secondary,
.nav-tertiary,
.footer .seals,
.recipe-wrapper.new ~ *,
.logo-top {
    display: none !important;
}

img.do-print {
    -webkit-print-color-adjust:exact;
    display: block !important;
    position: absolute;
    left:auto;
    right:0;
    top:100px;
    width:35%;
    max-height: 300px;
}

img.recipe-image {
    display: block !important;
    margin: 0 auto;
}

.cheese-action {
    visibility: hidden;
    overflow: hidden;
    height: 0;
    margin-bottom: 30px;
}